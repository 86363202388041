import { Icon } from '@iconify/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/react';

import pamplemousse from '../../../public/pamplemousse.png';

export const UnAuthenticated: React.FC = () => {
  const isLoginPage = useRouter().pathname === '/login';
  const handleSignInClick = async () =>
    await signIn('google', { callbackUrl: '/' });

  const handleLinkedinSignInClick = async () =>
    await signIn('linkedin', { callbackUrl: '/' });

  return (
    <>
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center gap-y-7 text-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <Image
            className="mx-auto h-24 w-auto"
            src={pamplemousse}
            alt="Pamplemousse"
          />
          <h1 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {isLoginPage
              ? 'Connectez-vous à votre compte'
              : 'Créez votre compte compte gratuit'}
          </h1>
        </div>
        {!isLoginPage && (
          <p className="text-md align-center mb-3 flex  text-gray-600">
            Et commencer à générer des factures facilement et rapidement
          </p>
        )}
      </div>
      <button
        onClick={handleSignInClick}
        className="flex items-center justify-center gap-x-4 rounded-lg border border-gray-200 px-6 py-3 font-medium text-gray-600  shadow-xl shadow-primary-400 hover:shadow-xl hover:shadow-primary-500 bg-white"
      >
        <GoogleLogoIcon />
        <span className="ml-3">Continuez avec Google</span>
      </button>

      <button
        onClick={handleLinkedinSignInClick}
        className="flex items-center justify-center gap-x-4 rounded-lg border border-gray-200 px-6 py-3 font-medium text-gray-600  shadow-xl shadow-primary-400 hover:shadow-xl hover:shadow-primary-500 bg-white"
      >
        <Icon icon="logos:linkedin-icon" />
        <span className="ml-3">Continuez avec LinkedIn</span>
      </button>
      <div className="mt-6 flex items-center justify-center gap-x-1 text-sm">
        <p>{isLoginPage ? "Vous n'avez pas de compte?" : 'Déjà un compte?'}</p>
        <Link
          href={isLoginPage ? '/signup' : '/login'}
          className="text-blue-500 underline transition-all hover:text-blue-900"
        >
          {!isLoginPage ? "S'inscrire" : 'Se connecter'}
        </Link>
      </div>
    </>
  );
};

const GoogleLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="24px"
    height="24px"
  >
    <path
      fill="#FFC107"
      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
    />
    <path
      fill="#FF3D00"
      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
    />
    <path
      fill="#4CAF50"
      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
    />
    <path
      fill="#1976D2"
      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
    />
  </svg>
);
