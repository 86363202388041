import { useSession } from 'next-auth/react';

import { Container } from '../components/auth/Container';
import { UnAuthenticated } from '../components/auth/Unauthenticated';
import { Loader } from '../components/common/Loader';
import Layout from '../layouts';

const LoginPage = () => {
  const { status } = useSession();

  const isLoading = status === 'loading';
  const isUnAuthenticated = status === 'unauthenticated';

  return (
    <Layout title="Continue">
      <Container>
        <>
          {isLoading && <Loader />}

          {isUnAuthenticated && <UnAuthenticated />}
        </>
      </Container>
    </Layout>
  );
};

export default LoginPage;
